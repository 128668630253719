// import { navigate } from '@reach/router'
import React, { memo } from 'react';
import { connect } from "react-redux"
import {
  clearPropertiesAction,
  setFilterHome,
  REDUX_UPDATE_FILTERS,
} from "../redux/propertiesDucks"
import { useRef } from "react"
import { useState, useEffect } from "react"
import Searcher from "./autocomplete"
// import SearchByAddress from "./autocompleteAddress"
// import SearcherPro from "./autocompletePro"
// import { useNavigate } from '@reach/router'
import { graphql, navigate, useStaticQuery } from "gatsby"
import toast, { Toaster } from "react-hot-toast"
import { getArrayOperationById,getArrayTypesById,getSumaryFilterDefault, getTypeCount } from "../helpers/helper.filters"

import $ from "jquery"
import { Markup } from "interweave"
import { Modal } from "react-bootstrap"
import { useLocation } from '@reach/router';
import { useGetTotalFiltersQuery } from '../redux/originalTokkoApi/properties';
import { usePrefetch, useGetPropertiesQuery } from '../redux/middlewareTokkoApi/properties';
import { makeFormDataByFilters } from '../helpers/helper.filters';

const isBrowser = typeof window !== "undefined";

const Search = ({
  loading_count,
  searchHome,
  dispatch,
  skeleton,
}) => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys{
          tokko
        }
      }
    }
  `)
  
  const MediaPro =false
  const  locationsAdv = false
  
  const [openType, setOpenType] = useState(false)
  const [searcherType, setSearcherType] = useState("")
  const [openOperation, setOpenOperation] = useState(false)
  const [openLocation, setOpenLocation] = useState(false)
  const [show, setShow] = useState({
    operation: false,
    location: false,
    type: false,
  })

  const { data: allTokkoFilterData, isLoading: isLoadingAllTokkoFilters } = useGetTotalFiltersQuery({"API_KEY":realEstate.keys.tokko});
  const { data: filtersTokkoByOperation, isLoading: isLoadingByOperationTokkoFilters} = useGetTotalFiltersQuery({"API_KEY":realEstate.keys.tokko,"OPERATION":searchHome.operation.toLowerCase()});
  const { data: filtersTokkoByLocationLite, isLoading: isLoadingFiltersTokkoByLocationLite} = useGetTotalFiltersQuery({"API_KEY":realEstate.keys.tokko,"OPERATION":searchHome.operation.toLowerCase(),"TYPE":getArrayTypesById(searchHome.type,allTokkoFilterData)});
  
  const handleClose = () => setShow(false)

  // const navigate = useNavigate();
  const refOperation = useRef()
  const refType = useRef()
  const [locations, setLocation] = useState([])
  const history = useLocation();

  const [errorInput, setErrorInput] = useState(false)

  const [router, setRouter] = useState({
    location: "",
    type: "",
    operation: 'venta',
    address: "",
  })

  useEffect(() => {
    if (filtersTokkoByLocationLite) {
      setLocation([])
      for (
        let index = 0;
        index < filtersTokkoByLocationLite.objects.locations.length;
        index++
      ) {
        // if(locations.length <= 0){
        setLocation(locations => [
          ...locations,
          filtersTokkoByLocationLite.objects.locations[index].location_name,
        ])
      }
      // }
    }
  }, [filtersTokkoByLocationLite])

  const updateRoute = value => {
    setRouter(router => ({
      ...router,
      [value.current.name]: value.current.value,
    }))
  }

  useEffect(() => {
    switch (router?.operation?.toLowerCase()) {
      case 'venta':
        updateOperation({ id: 1, name: 'venta'})
        break
      case 'alquiler':
        updateOperation({ id: 2, name: 'alquiler' })
        break
        case 'alquiler-temporario':
        updateOperation({ id: 3, name: 'alquiler-temporario' })
        break
      default:
    }
  }, [router.operation])

  const goRoute = event => {
    event.preventDefault()
    if (true) {
      dispatch(clearPropertiesAction())
      let routerLink = searchHome.operation.toLowerCase().replace(/ /g, "-")
      routerLink +=
        searchHome.type.length > 0
          ? "/" +
            searchHome.type.map((element, index) =>
              element.name
                .toLowerCase()
                .replace(/ /g, "-")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
          : ""
      routerLink +=
        searchHome.location.length > 0
          ? "/" +
            searchHome.location.map((element, index) =>
              element.name
                .toLowerCase()
                .replace(/ /g, "-")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
          : ""
      routerLink += searchHome.address
        ? "/en-calle-" +
          searchHome.address
            .replaceAll(" ", "-")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        : ""
      navigate(routerLink)
    }
  }

  const updateOperation = value => {
    dispatch(
      setFilterHome({
        ...searchHome,
        operation: value.name,
        type: [],
        address: "",
      })
    )
  }

  const handleTogglerSelect = select => {
    //Function to open/close custom-selects
    if (typeof window !== undefined) {
      var width = $(window).width()
      // if(width > 919){
      setOpenLocation(false)
      setOpenType(false)
      setOpenOperation(false)
      switch (select) {
        case "operation": {
          setOpenOperation(!openOperation)
          break
        }
        case "location": {
          setOpenLocation(!openLocation)
          break
        }
        case "type": {
          setOpenType(!openType)
          break
        }
        default: {
          break
        }
      }
      // }
      // else{
      // handleShow(select)
      // }
    }
  }

  const updateTypes = value => {
    if (searchHome.type.find(element => element.id === value.id)) {
      dispatch(
        setFilterHome({
          ...searchHome,
          type: searchHome.type.filter(element => element.id !== value.id),
        })
      )
    } else {
      dispatch(
        setFilterHome({
          ...searchHome,
          type: [...searchHome.type, value],
        })
      )
    }
  }

  function toMarkup(text, searcherOperation) {
    if (searcherOperation === "") {
      return text.toLowerCase()
    }
    text = text
      .toLowerCase()
      .replaceAll(
        searcherOperation.toLowerCase(),
        "<b>" + searcherOperation.toLowerCase() + "</b>"
      )
    return text
  }

  const checkResults = type => {
    if (type === "location") {
      for (let grandfather of getGrandfathers(allLocations)) {
        if (grandfather[0].toLowerCase().includes(searcher.toLowerCase())) {
          return false
        }
        for (let father of getFathers(grandfather)) {
          if (father[0].toLowerCase().includes(searcher.toLowerCase())) {
            return false
          }
          for (let children of getChildrens(father)) {
            if (children[0].toLowerCase().includes(searcher.toLowerCase())) {
              return false
            }
          }
        }
      }
      return true
    } else {
      for (let type of filtersTokkoByOperation.objects.property_types) {
        if (type.type.toLowerCase().includes(searcherType.toLowerCase())) {
          return false
        }
      }
      return true
    }
  }

  const makeElipsisTextType = (text, length) => {
    let resume = ""
    text.map(element => (resume += element))
    if (resume.length > length) {
      return resume.slice(0, length) + "..."
    }
    return resume
  }

  useEffect(() => {
    dispatch(setFilterHome({ ...searchHome, type: [], location: [] }))
    $("#type").val("all")
  }, [searchHome.operation])


  useEffect(() => {
    dispatch(setFilterHome({ ...searchHome, location: [] }))
  }, [searchHome.type])

  useEffect(() => {
    dispatch(
      REDUX_UPDATE_FILTERS({
        location: [],
        type: [],
        operation: "",
        environments: "",
        bedrooms: "",
        age: "",
        address: "",
        producer_id: "",
        price: {
          type: "",
          priceMin: "",
          priceMax: "",
          is_off: false,
        },
        surface: {
          type: "",
          surfaceMin: "",
          surfaceMax: "",
        },
        environments_types: [],
        generals: [],
        services: [],
        specials: [],
      })
    )
    dispatch(
      setFilterHome({ operation: "venta", location: [], type: [], address: "" })
    )
  }, [])

  useEffect(() => {
    dispatch(
      setFilterHome({
        location: [],
        type: [],
        operation: "venta",
      })
    )
    dispatch(
      REDUX_UPDATE_FILTERS({
        location: [],
        type: [],
        operation: "",
        environments: "",
        bedrooms: "",
        price: {
          type: "",
          priceMin: "",
          priceMax: "",
        },
        surface: {
          type: "",
          surfaceMin: "",
          surfaceMax: "",
        },
        environments_types: [],
        generals: [],
        services: [],
        specials: [],
      })
    )
  }, [history])

  if (isBrowser) {
    $(document).on("mouseover", function (e) {
      if (e?.target?.className) {
        if ((typeof e?.target?.className === 'object') 
             ? '' : e?.target?.className?.includes("not-closer")) {
        } else {
          setOpenType(false)
          setOpenLocation(false)
          setOpenOperation(false)
        }
      }
    })
  
    $(document).on("click", function (e) {
      if (
        e.target.className === "dropdown-select btn btn-main no-hover true" ||
        (typeof e?.target?.className === 'object') 
         ? '' : e?.target?.className?.includes("not-closer")
      ) {
      } else {
        setOpenType(false)
        setOpenLocation(false)
        setOpenOperation(false)
      }
    })
  }

  // const prefetchProperties = usePrefetch('getProperties')

  return (
    <div
      class={
        " search-home d-flex align-items-center py-3 " +
        (locationsAdv ? "pro-searcher py-lg-0 " : "lite-searcher py-lg-4 ") + (isLoadingAllTokkoFilters && " skeleton ") 
      }
    >
        <div class="container-fluid" style={{maxWidth:"100%"}}>
          <form
            onSubmit={goRoute}
            class="row justify-content-center no-gutters align-items-stretch px-xl-5 search-form mb-0"
          >
            <div className={locationsAdv ? "col-lg-6" : "col-lg-6"}>
              <div className="row">
                <div class="col-12 my-2 col-lg-7 px-2 pr-lg-3">
                  <div
                    class="btn-group w-100"
                    role="group"
                    aria-label="Basic example"
                  >
                        {allTokkoFilterData &&
                        allTokkoFilterData.objects.operation_types.find(
                          element => element.operation_type === 1
                        ) ? (
                          <button
                            onClick={() =>
                              updateRoute({
                                current: { value: 'venta', name: "operation" },
                              })
                            }
                            type="button"
                            class={
                              "btn btn-primary " +
                              (router?.operation?.toLowerCase() === 'venta'
                                ? "active"
                                : "")
                            }
                          >
                            {'Venta'}
                          </button>
                        ) : (
                          ""
                        )}
                        {allTokkoFilterData &&
                        allTokkoFilterData.objects.operation_types.find(
                          element => element.operation_type === 2
                        ) ? (
                          <button
                            onClick={() =>
                              updateRoute({
                                current: { value: 'alquiler', name: "operation" },
                              })
                            }
                            type="button"
                            class={
                              "btn mx-auto btn-primary " +
                              (router?.operation?.toLowerCase() === 'alquiler'
                                ? "active"
                                : "")
                            }
                          >
                          {'Alquiler'}
                          </button>
                        ) : (
                          ""
                        )}
                        {allTokkoFilterData &&
                        allTokkoFilterData.objects.operation_types.find(
                          element => element.operation_type === 3
                        ) ? (
                          <button
                            onClick={() =>
                              updateRoute({
                                current: {
                                  value: 'alquiler-temporario',
                                  name: "operation",
                                },
                              })
                            }
                            type="button"
                            class={
                              "btn btn-primary " +
                              (router?.operation?.toLowerCase() ===
                              'alquiler-temporario'
                                ? "active"
                                : "")
                            }
                          >
                            {'Alquiler Temporario'}
                          </button>
                        ) : (
                          ""
                        )}
                  </div>
                </div>
                <div
                  class="col-12 my-2 col-lg-5 px-2 pr-lg-3 ps-lg-1"
                  style={{ zIndex: 10 }}
                >
                  <div
                    className={
                      "d-lg-flex content-field content-select not-closer " +
                      (!openType && "close")
                    }
                  >
                    <div
                      onClick={() =>
                        toast.error(
                          "Debes primero seleccionar un tipo de operación."
                        )
                      }
                      className={
                        "z-index-top " +
                        (searchHome.operation === "Tipo de operación"
                          ? "d-block"
                          : "d-none")
                      }
                    ></div>
                    <input
                      type="text"
                      name="search-type"
                      value={searcherType}
                      onChange={e =>
                        setOpenType(true) + setSearcherType(e.target.value)
                      }
                      placeholder={
                        searchHome.type.length > 0
                          ? makeElipsisTextType(
                              searchHome.type.map(
                                (element, index) =>
                                  element.name +
                                  (index < searchHome.type.length - 1 ? ", " : "")
                              ),
                              25
                            )
                          : "Tipo de propiedad"
                      }
                      className="dropdown-select border-round btn btn-main no-hover not-closer text-start br-true true"
                      onClick={e =>
                        (window.screen.width < 992
                          ? setShow({ ...show, type: true })
                          : handleTogglerSelect("type")) + e.preventDefault()
                      }
                    />
                    <i className="not-closer"></i>
                    <div className="float-select not-closer width-custom small height-fixed">
                      <div className={"my-wrapper position-relative not-closer "}>
                        {filtersTokkoByOperation ? (
                          <>
                            {filtersTokkoByOperation.objects.property_types
                              .map((item, index) =>
                                searcherType !== "" ? (
                                  <>
                                    {item.type
                                      .toLowerCase()
                                      .includes(searcherType.toLowerCase()) && (
                                      <div
                                        className={
                                          "flex-input not-closer " +
                                          (loading_count &&
                                          !searchHome.type.find(
                                            element => element.id === item.id
                                          )
                                            ? "disabled "
                                            : "") +
                                          (getTypeCount(
                                            filtersTokkoByOperation.objects,
                                            item
                                          ) === 0 && "opacity-filter")
                                        }
                                      >
                                        <input
                                          disabled={loading_count}
                                          type="checkbox"
                                          className="not-closer"
                                          name=""
                                          checked={searchHome.type.find(
                                            element => element.id === item.id
                                          )}
                                          onChange={e =>
                                            updateTypes({
                                              id: item.id,
                                              name: item.type,
                                            })
                                          }
                                          id={item.type}
                                          value={item.type}
                                        />
                                        <label
                                          className="not-closer"
                                          htmlFor={item.type}
                                        >
                                          <div className="square not-closer custom-span "></div>
                                          <div className="mark-up text-capitalize not-closer">
                                            {" "}
                                            <Markup
                                              content={toMarkup(
                                                item.type,
                                                searcherType
                                              )}
                                            />
                                          </div>
                                        </label>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div
                                    className={
                                      "flex-input not-closer " +
                                      (loading_count &&
                                      !searchHome.type.find(
                                        element => element.id === item.id
                                      )
                                        ? "disabled "
                                        : "") +
                                      (getTypeCount(
                                        filtersTokkoByOperation.objects,
                                        item
                                      ) === 0 && "opacity-filter")
                                    }
                                  >
                                    <input
                                      disabled={loading_count}
                                      type="checkbox"
                                      className="not-closer"
                                      name=""
                                      checked={searchHome.type.find(
                                        element => element.id === item.id
                                      )}
                                      onChange={e =>
                                        updateTypes({
                                          id: item.id,
                                          name: item.type,
                                        })
                                      }
                                      id={item.type}
                                      value={item.type}
                                    />
                                    <label
                                      className="not-closer"
                                      htmlFor={item.type}
                                    >
                                      <div className="square not-closer custom-span "></div>
                                      <div className="mark-up not-closer">
                                        {" "}
                                        <Markup
                                          content={toMarkup(
                                            item.type,
                                            searcherType
                                          )}
                                        />
                                      </div>
                                    </label>
                                  </div>
                                )
                              )}
                            {checkResults("type") && searcherType !== "" && (
                              <span className="mb-4">
                                No hay resultados sobre <b> "{searcherType}"</b>.
                              </span>
                            )}
                            <div className="control-float not-closer position-sticky w-100 d-flex justify-content-between small ">
                              <buttton
                                onClick={() =>
                                  dispatch(
                                    setFilterHome({ ...searchHome, type: [] })
                                  )
                                }
                                className="btn btn-white not-closer"
                              >
                                Limpiar
                              </buttton>
                              <buttton
                                onClick={() => setOpenType(false)}
                                className="btn not-closer btn-red border"
                              >
                                Aplicar
                              </buttton>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class={locationsAdv ? "col-lg-6" : "col-12 col-lg-6"}>
              <div class="row no-gutters d-flex align-items-stretch">
                <div
                  class={
                    "col-12 my-2 col-lg-5 px-2 pe-lg-0 " +
                    (locationsAdv ? "col-lg-5" : "col-lg-10")
                  }
                >
                  <div
                    class={"content-select " + (errorInput ? "error-border" : "")}
                  >
                    {locationsAdv ? (
                      <></>
                    ) : (
                      <Searcher lang={locations} updateRoute={updateRoute} />
                    )}
                  </div>
                </div>
                <div
                  class={
                    "col-12 my-2 col-lg-5 px-2 pe-lg-2 " +
                    (!locationsAdv && "d-none")
                  }
                >
                  <div
                    class={"content-select " + (errorInput ? "error-border" : "")}
                  >
                    {locationsAdv ? (
                      <></>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div class={"col-12 my-2 px-2 px-lg-0 col-lg-2 " + (!locationsAdv ? "ps-lg-3" : 'ps-lg-0')}>
                  <button class="btn btn-white">BUSCAR</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      {/* Modal Type */}
      <Modal
        show={show.type}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="modal-mobile body-no-padding"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Tipos de propiedades</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <input type="text" className="w-100 search-mobile" name="search-type" value={searcherType} placeholder={"Buscar por tipo"} onChange={(e) => setSearcherType(e.target.value)} /> */}
          {filtersTokkoByOperation
            ? filtersTokkoByOperation.objects.property_types
                .map(
                  (item, index) =>
                    item.type
                      .toLowerCase()
                      .includes(searcherType.toLowerCase()) && (
                      <div
                        className={
                          "flex-input not-closer z-index-18 " +
                          (getTypeCount(
                            filtersTokkoByOperation.objects,
                            item
                          ) === 0 && "opacity-filter")
                        }
                      >
                        <input
                          disabled={loading_count}
                          type="checkbox"
                          className="not-closer"
                          name=""
                          checked={searchHome.type.find(
                            element => element.id === item.id
                          )}
                          onChange={e =>
                            updateTypes({ id: item.id, name: item.type })
                          }
                          id={item.type}
                          value={item.type}
                        />
                        <label className="not-closer" htmlFor={item.type}>
                          <div className="square custom-span"></div>
                          {item.type}
                        </label>
                      </div>
                    )
                )
            : ""}
        </Modal.Body>
        <Modal.Footer>
          <div
            className="control-float position-sticky w-100 d-flex justify-content-between not-closer"
            style={{ maxWidth: "none" }}
          >
            <buttton
              onClick={() =>
                dispatch(setFilterHome({ ...searchHome, type: [] }))
              }
              className="btn btn-white not-closer"
            >
              Limpiar
            </buttton>
            <buttton
              onClick={() => setShow({ ...show, type: false })}
              className="btn btn-red border not-closer"
            >
              Aplicar
            </buttton>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default memo(connect(
  state => ({
    totalListFilters: state.properties.totalListFilters,
    listLocationLite: state.properties.listLocationLite,
    listFiltersByOperation: state.properties.listFiltersByOperation,
    searchHome: state.properties.searchHome,
    loading_count: state.properties.loading_count,
  }),
  null
)(Search))
