import React from 'react'
import Card from '../development-card'
import { Link, graphql, useStaticQuery } from 'gatsby';

import { connect } from 'react-redux'

import {getFeatured} from '../../helpers/helper.developments'
import { useGetDevelopmentsQuery } from '../../redux/middlewareTokkoApi/developments';

const developments = ({developments,dispatch}) => {
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                keys{
                    tokko
                }
                sections {
                  home {
                    developments {
                      subtitle
                      sup_title
                      title
                      button {
                        link
                        value
                      }
                    }
                  }
                }
              }
        }`)

    const { data: allDevelopmentsData, isLoading: isLoadingDevelopments } = useGetDevelopmentsQuery(realEstate.keys.tokko);
        
    return (
        <section id="featured-devs" className={allDevelopmentsData?.length === 0 && !isLoadingDevelopments ? 'd-none' : ''}>
            {/* <div className="container-fluid pt-lg-5 my-lg-5 ">
                <h1 className="title-2 text-center">{realEstate.sections.home.developments.sup_title}</h1>
            </div> */}
            <div class="container-fluid pt-3 mt-lg-3 pt-lg-3">
                <h2 class="section-title py-5 text-left">{realEstate.sections.home.developments.title}</h2>
            </div>
            <div class="devs mt-4">
                <div class="container-fluid">
                    <div class="row no-gutters">     
                        {
                        !isLoadingDevelopments ?
                            getFeatured(allDevelopmentsData).map(development => (
                                    <div  class="col-md-4 mb-4 mb-lg-0 p-lg-3">
                                        <Card development={development} />
                                    </div>                  
                                ))
                                : [1,2,3].map(() => (
                                    <div  class="col-md-4 mb-4 mb-lg-0 p-lg-3">
                                        <Card skeleton={true} />
                                    </div>    
                                ))
                            }       
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            {/* <h5 class="text-center subtitle_new">{realEstate.sections.home.developments.subtitle}</h5> */}
                            <Link to={realEstate.sections.home.developments.button.link} className="mt-5 d-inline-block btn">{realEstate.sections.home.developments.button.value}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    ) 
}

export default connect(state => ({
    developments: state.developments.developments,
}),null)(developments);